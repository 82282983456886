<template>
  <!-- 监控中心通知管理 -->
  <div
    class="monitoring-notification-managa"
    ref="monitoringNotificationManaga"
  >
    <div class="function-buttons" ref="functionButtons">
      <el-button
        type="primary"
        size="small"
        @click="onAddNotification"
        v-if="$store.state.menu.nowMenuList.indexOf('新增') >= 0"
        >新增</el-button
      >
    </div>
    <div class="form-area analysisForm" ref="form">
      <el-form
        :model="form"
        ref="formInline"
        class="demo-form-inline"
        label-position="left"
        label-width="82px"
      >
        <el-form-item label="所属车队：">
          <companySelectForSearch
            ref="companySelectForSearch"
            :multiple="false"
            :searchable="true"
            @getValue="getGroupIds"
          />
        </el-form-item>
        <el-form-item label-width="0">
          <el-button
            type="primary"
            size="small"
            :loading="searchLoading"
            @click="onSearch()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 平台消息模板 -->
    <div class="platform-notifition" ref="platformNotifition">
      <div>平台消息：</div>
      <div class="ipt">
        <el-input
          type="textarea"
          :rows="6"
          placeholder="请输入内容"
          v-model="textareaValue"
        >
        </el-input>
      </div>
      <el-button
        type="primary"
        size="small"
        :loading="editLoadingPalt"
        v-if="$store.state.menu.nowMenuList.indexOf('默认消息设置') >= 0"
        @click="onEdit()"
        >保存</el-button
      >
    </div>
    <el-table :data="tableData" stripe :height="tableHeight">
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属车队"
        width="250"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="operateUserName"
        label="操作人"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="操作时间"
        width="180"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="showBeginTime"
        label="消息展示时间"
        width="220"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            >{{ scope.row.showBeginTime.split(' ')[0] }} 至
            {{ scope.row.showEndTime.split(' ')[0] }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="message" label="消息内容"></el-table-column>
    </el-table>
    <div class="pagination" ref="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <!-- 新增消息 -->
    <el-dialog
      title="新增"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
    >
      <div class="message-form">
        <el-form
          ref="addForm"
          :model="addForm"
          label-width="120px"
          size="small"
          :rules="rules"
          class="dialog-form"
        >
          <el-form-item label="通知企业：" prop="companyId">
            <companySelectForSearch
              ref="companySelectForSearch"
              :multiple="false"
              :searchable="true"
              @getValue="getGroupIdAddform"
            />
          </el-form-item>
          <el-form-item label="展示时间：" prop="time">
            <el-date-picker
              v-model="addForm.time"
              type="daterange"
              align="right"
              cellClassName="timeRangePicker"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              :picker-options="pickerDateOptions"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="消息内容：" prop="message">
            <el-input
              type="textarea"
              :rows="8"
              placeholder="请输入内容"
              v-model="addForm.message"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible = false"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="onSave()"
          :loading="editLoading"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import companySelectForSearch from '@/components/treeSelect/companySelectForSearch.vue';
import {
  insertGpsMonitorMsgApi,
  gpsMonitorMsgPageApi,
  getDefaultMsgApi,
  setGpsDefaultMonitorMsgApi
} from '@/api/lib/gps-api.js';
import {
  getRangeDay,
  formatDate,
  formatDay,
  getStartDate,
  checkTimeLimitDay,
} from "@/common/utils/index";
export default {
  components: {
    companySelectForSearch,
  },
  data () {
    return {
      form: {
        companyId: null,
        currentPage: 1,
        pageSize: 10,
      },
      pickerDateOptions: {
        disabledDate (time) {
          return time.getTime() < Date.now()  - 3600 * 1000 * 24;
        },
      },
      searchLoading: false,
      editLoadingPalt: false,
      textareaValue: '',
      tableData: [],
      tableHeight: 0,
      total: 0,
      dialogVisible: false,
      addForm: {
        companyId: null,
        message: '',
        time: "",
      },
      rules: {
        companyId: [{ required: true, message: "请选择", trigger: "blur" }],
        time: [
          { required: true, message: "请选择时间", trigger: "change" }
        ],
        message: [{ required: true, message: "请输入内容", trigger: "blur" }]
      },
      editLoading: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.computeHeight();
      this.onSearch();
      this.queryDefaultMsgValue();
      window.addEventListener('resize', this.computeHeight, false);
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.computeHeight)
  },
  methods: {
    queryDefaultMsgValue () {
      getDefaultMsgApi().then(res => {
        if (res.code === 1000) {
          this.textareaValue = res.data;
        }
      })
    },
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          const { companyId, message } = this.addForm;
          let data = {
            companyId,
            message,
            showBeginTime: formatDay(this.addForm.time[0]),
            showEndTime: formatDay(this.addForm.time[1])
          }
          this.editLoading = true;
          insertGpsMonitorMsgApi(data).then(res => {
            console.log(res);
            if (res.code === 1000) {
              this.$message.success(res.msg);
              this.getListByField();
              this.dialogVisible = false;
            } else {
              this.$message.error(res.msg);
            }
            this.editLoading = false;
          }).catch(err => {
            this.editLoading = false;
          })
        }
      })
    },
    // 获取车组数据
    getGroupIdAddform (val) {

      /**
       * 不能选择平台，companyId为1禁掉
       */
      if (val.length && val[0] === 1) {
        this.$message.warning('平台消息通知请在页面修改！');
        this.addForm.companyId = null;
        return
      } else {
        this.$refs.addForm.clearValidate();
        this.addForm.companyId = val.length === 0 ? null : val[0];
      }
    },
    // 计算表格高度
    computeHeight () {
      const wholeHeight = this.$refs.monitoringNotificationManaga.clientHeight;
      const buttonsHeight = this.$refs.functionButtons.clientHeight;
      const formHeight = this.$refs.form.clientHeight;
      const platformNotifitionHeight = this.$refs.platformNotifition.clientHeight;
      const paginationHeight = this.$refs.pagination.clientHeight;
      this.tableHeight =
        wholeHeight -
        16 -
        buttonsHeight -
        16 -
        formHeight -
        platformNotifitionHeight -
        16 -
        paginationHeight -
        16;
    },
    // 当前页改变
    handleCurrentChange (page) {
      this.form.currentPage = page;
      this.getListByField()
    },
    // 每页显示条数改变
    handleSizeChange (size) {
      this.form.currentPage = 1;
      this.form.pageSize = size;
      this.getListByField();
    },
    // 获取车组数据
    getGroupIds (val) {
      this.form.companyId = val.length === 0 ? null : val[0];
    },
    onAddNotification () {
      this.dialogVisible = true;
    },
    // 点击查询
    onSearch () {
      this.searchLoading = true;
      this.form.currentPage = 1;
      this.form.pageSize = 10;
      this.getListByField();
    },
    getListByField () {
      gpsMonitorMsgPageApi(this.form).then(res => {
        if (res.code === 1000) {
          this.tableData = res.data.list || [];
          this.total = res.data.total;
          if (res.data.list.length === 0 && data.currentPage !== 1) {
            this.form.currentPage -= 1;
            this.getListByField();
          }
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.msg);
        }
        this.searchLoading = false;
      }).catch(err => {
        this.searchLoading = false;
      })
    },
    onEdit () {
      this.editLoadingPalt = true;
      setGpsDefaultMonitorMsgApi({ message: this.textareaValue }).then(res => {
        if (res.code === 1000) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.editLoadingPalt = false;
      }).catch(err => {
        this.editLoadingPalt = false;
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.monitoring-notification-managa {
  height: 100%;
  width: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  .platform-notifition {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 0 12px 90px;
    .ipt {
      margin: 0 32px;
      flex: 1;
    }
  }
  .message-form {
    /deep/ .el-form {
      .el-select {
        width: 80%;
      }
      .el-form-item {
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner,
        .el-input__inner,
        .el-textarea__inner {
          width: 80%;
        }
        .el-range-separator {
          height: 26px;
          line-height: 26px;
        }
        .el-input__inner {
          &[cellclassname='timeRangePicker'] {
            width: 64%;
            padding: 0 10px;
          }
        }
        .el-input__suffix {
          right: 20%;
        }
      }
    }
  }
}
</style>